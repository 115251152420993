#hamburger-menu {
  position: fixed;
  top: 0;
  right: -400px;
  height: 100vh;
  width: 400px;
  background-color: #fff;
  transition: 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  z-index: 10;
  &.show {
    right: 0;
  }
  @media screen and (max-width: 970px) {
    width: 100vw;
    right: -100vw;
  }

  .heading {
    height: 88px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 2rem;
    .center {
      display: none;
    }
    @media screen and (max-width: 970px) {
      & > * {
        width: calc(100% / 3);
      }
      .center {
        display: flex;
        justify-content: center;
      }
    }

    span {
      color: var(--greyDarkColor);
      font-size: 0.9rem;
      font-weight: 500;
    }

    .btn {
      line-height: 3rem;
      height: 3rem;
      border-radius: 3rem;
      padding: 0 32px;
      font-weight: 500;
      font-size: 1rem;

      div {
        display: none;
        @media screen and (max-width: 992px) {
          display: block;
        }
      }
    }

    svg {
      height: 48px;
      width: 48px;
      border-radius: 50%;
      padding: 0.5rem;
      border: 2px solid var(--greyColor);
      @media screen and (max-width: 970px) {
        height: 2rem;
        width: 2rem;
      }
    }
  }

  svg {
    cursor: pointer;
  }

  .social-list {
    @media screen and (max-width: 768px) {
      padding-top: 16px;
      justify-content: flex-end;
    }
    svg {
      height: 1.2rem;
      cursor: pointer;
    }
  }
  .user-menu-ham {
    padding: 3vh 1.5rem;
    @media screen and (min-width: 768px) {
      display: none;
    }
  }
  .top {
    padding: 3vh 1.5rem;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 768px) {
      padding: 1.5vh 2rem;
    }
    & > * + * {
      margin-top: 2.5vh;
    }

    a {
      font-size: 1.5rem;
      font-weight: 600;
      display: flex;
      align-items: center;
      & > * + * {
        margin-left: 1rem;
      }

      svg {
        height: 2rem;
        width: 2rem;
      }
    }

    a:nth-child(1) {
      color: var(--acqua);
      svg path {
        stroke: url(#acqua);
      }
    }

    a:nth-child(2) {
      color: var(--ambiente);
      svg line,
      svg path {
        stroke: url(#ambiente);
      }
    }

    a:nth-child(3) {
      color: var(--energia);
      svg polygon {
        stroke: url(#energia);
      }
    }
  }

  .line {
    height: 2px;
    width: 100%;
    background-color: var(--greyColor);
  }

  .sections-menu {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 1.5rem;
    font-weight: 600;
    padding: 0.5rem 0;
    @media screen and (max-width: 768px) {
      justify-content: unset;
    }

    .voice {
      user-select: none;
      & > div,
      a {
        padding: 1.5vh 2rem;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &:hover {
          background-color: rgba(#000, 0.1);
        }
        @media screen and (max-width: 370px) {
          padding: 1vh 2rem;
        }
      }
    }

    .bottom-sections-menu {
      padding: 1rem 1.5rem;
      @media screen and (max-width: 576px) {
        padding: 0rem 1.5rem 3rem;
      }
    }
  }

  .sub-menu {
    position: absolute;
    top: 88px;
    right: -400px;
    width: 100%;
    height: calc(100% - 88px);
    overflow-x: hidden;
    overflow-y: auto;
    transition: 0.3s ease-in-out;
    background: #fff;
    opacity: 0;
    @media screen and (max-width: 768px) {
      right: -100vw;
    }

    .voice {
      user-select: none;
      padding: 0.35rem 2rem;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:hover {
        background-color: rgba(#000, 0.1);
      }
    }
    &.show {
      opacity: 1;
      right: 0;
    }

    .sub-menu-top {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 2rem;
      justify-content: space-between;

      span {
        font-size: 1.5rem;
        font-weight: 600;
      }
    }

    .bottom {
      display: flex;
      flex-direction: column;
      font-size: 1.2rem;
      font-weight: 400;
      & > * + * {
        margin-top: 1rem;
      }
    }
  }
}
