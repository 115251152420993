@import-normalize;
:root {
  // General
  --black: #222;
  --blueColor: #00457c;
  --greyColor: #ededed;
  --greyDarkColor: #d8d8d8;
  --greyDarkestColor: #9e9e9e;
  // Debug
  --sample: rgba(255, 0, 0, 0.2);
  // Sector
  --darkGradient: linear-gradient(
    135deg,
    #000,
    #444,
    #000
);
  --acquaGradient: linear-gradient(
    135deg,
    #0066a8 0%,
    #0a85bf 50%,
    #279fca 100%
  );
  --acqua: #0a85bf;
  --ambienteGradient: linear-gradient(
    135deg,
    #398d99 0%,
    #36b29b 50%,
    #06c96d 100%
  );
  --ambiente: #36b29b;
  --energiaGradient: linear-gradient(
    135deg,
    #ff8801 0%,
    #ffbd0d 50%,
    #eb650c 100%
  );
  --energia: #ff8801;
  // Area
  --affari: #fd4945;
  --lavoro: #c65efb;
  --giuridico: #b54a4e;
  --comunicazione: #7256fd;
  --amministrazione: #d2ce43;
  --strategie: #4a9b4a;
  --funerario: #0066a8;

  // Breakpoints
  --small: 576px;
  --medium: 768px;
  --large: 992px;
  --extraLarge: 1200px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  padding-top: 88px;
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  & > div:nth-child(2) {
    flex-grow: 1;
  }
}

body.no-scroll {
  height: 100%;
  overflow-y: hidden;
}

body,
button,
input {
  color: var(--black);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--greyColor);
  // background-color: #f5f5f5;
  font-size: 16px;
  font-family: "Gilroy", sans-serif;
}

a {
  text-decoration: none;
  color: var(--black);
  &:hover {
    color: var(--blueColor);
  }
}

ul {
  list-style: none;
}

button {
  border: none;
  cursor: pointer;
}

h2.general {
  font-size: 2.5rem;
  font-weight: 400;
  margin-bottom: 2rem;
  span {
    font-weight: 700;
  }
}
input {
  border: 2px solid var(--greyColor);
  outline-color: var(--greyDarkColor);
  background: #fff;
  padding: 0;
}

input[type="search"] {
  -webkit-appearance: textfield;
}

input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

h5 {
  font-size: 2rem;
  font-weight: 400;
}

h6 {
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 2rem;
}

span {
  &.general,
  &.date,
  &.category {
    font-size: 0.8rem;
    font-weight: 600;
    text-transform: uppercase;
  }
  &.date {
    color: var(--greyDarkestColor);
  }
}

select {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: transparent;
  border: none;
  padding: 0 1em 0 0;
  margin: 0;
  min-width: 200px;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  background-image: url("icons/caret-down-bold.svg");
  background-repeat: no-repeat;
  background-size: 1.5rem;
  background-position: 90% center;
}

.container {
  max-width: 1170px;
  margin: 0 auto;
  padding: 80px 0 100px 0;
  @media screen and (max-width: 768px) {
    padding: 40px 0 50px 0;
  }
}

.bg-white {
  background-color: #fff;
}

.btn {
  svg {
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
  }
  @media screen and (max-width: 768px) {
    &.file {
      // max-width: 80%;
      // padding: 2rem 1rem;
      svg {
        margin-right: 1rem;
        height: 1.5rem;
        width: 1.5rem;
        flex-shrink: 0;
      }
    }
  }
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 3rem;
  border: none;
  height: 2.2rem;
  border-radius: 2.2rem;
  padding: 0 1.2rem;
  font-size: 0.8rem;
  font-weight: 600;
  color: #fff;
  background: var(--blueColor);
  flex-shrink: 0;
  transition: 0.2s;
  &:hover {
    background: var(--black);
    color: #fff;
  }
  &.white {
    color: var(--black);
    background-color: #fff;
    &:hover {
      background: rgba(#fff, 0.9);
      filter: brightness(90%);
    }
  }
  &.small {
    height: 1.7rem;
    border-radius: 1.7rem;
    padding: 1rem 1.2rem;
    font-size: 0.6rem;
  }
  &.big {
    height: 4rem;
    border-radius: 4rem;
    padding: 0 4rem;
    font-size: 1.2rem;
    font-weight: 600;
  }
}

.lineH {
  height: 2px;
  width: 100%;
  background: var(--greyDarkColor);
}

.lineV {
  width: 2px;
  height: 100%;
  background: var(--greyDarkColor);
}
.bold {
  font-weight: 600;
}
@media screen and (max-width: 1170px) {
  .pad-mobile {
    // padding-left: 1rem !important;
    // padding-right: 1rem !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
.no-print {
  @media print {
    display: none !important;
  }
}
