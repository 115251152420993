#documento {
  .heading {
    padding-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > * {
      width: calc(100% / 3);
    }
    .back {
      display: flex;
      flex: 1;
      align-items: center;
      & > * + * {
        margin-left: 1rem;
      }
      cursor: pointer;
      svg {
        height: 3rem;
        width: 3rem;
        padding: 0.5rem;
      }
      .general {
        @media screen and (max-width: 576px) {
          display: none;
        }
      }
    }
    .center {
      display: flex;
      flex: 5;
      justify-content: center;
      .sample {
        height: 2rem;
        width: 10rem;
        background: var(--sample);
      }
    }
    .right {
      text-transform: uppercase;
      font-weight: 600;
      text-align: end;
      flex: 1;
    }
    .details {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      & > * + * {
        margin-left: 1rem;
      }
    }
  }
  .content {
    h2 {
      font-weight: 600;
      margin: 1rem 0 2rem;
    }
    .sample {
      height: 32rem;
      background: var(--sample);
      content: "";
      margin-bottom: 3rem;
    }
    p {
      font-size: 1.3rem;
      margin-bottom: 3rem;
    }
    .share {
      display: flex;
      justify-content: center;
      & > * + * {
        margin-left: 1.5rem;
      }
      align-items: center;
      margin-bottom: 2rem;
      .social {
        display: flex;
        align-items: center;
        & > * + * {
          margin-left: 1rem;
        }
        svg {
          font-size: 1.5rem;
        }
      }
    }
    .buttons {
      display: flex;
      justify-content: center;
      & > * + * {
        margin-left: 2rem;
      }
      margin-bottom: 4rem;
      .btn {
        display: flex;
        align-items: center;
        & > * + * {
          margin-left: 0.5rem;
        }
        svg {
          height: 1rem;
          width: 1rem;
          *:not(:first-child) {
            stroke: #fff;
          }
        }
      }
    }
  }
}
